<template>
    <v-card tile hover>
        <v-subheader>Edit Account Information</v-subheader>
        <v-divider></v-divider>
            <v-layout row wrap>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="accountInfo.email"
                            :rules="[rules.email,rules.required]"
                            required
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>Email</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="accountInfo.alternate_email"
                            label="Alternative Email"
                            :rules="[rules.email]"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="accountInfo.first_name"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>First Name</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="accountInfo.last_name"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>Last Name</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
            </v-layout>
    </v-card>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'
    // import {mapState} from 'vuex'
    export default {
        name: "Account",
        components: {RequiredDot},
        props: {
          accountInfo: Object
        },
        data(){
            return {
                rules: {
                    ...rules
                }
            }
        },
        // computed: {
        //     ...mapState('account',{
        //         accountInfo: state => state.accountInfo
        //     })
        // }
    }
</script>

