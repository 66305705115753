var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "", hover: "" } },
    [
      _c("v-subheader", [_vm._v("Edit Account Information")]),
      _c("v-divider"),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: {
                  rules: [_vm.rules.email, _vm.rules.required],
                  required: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [_c("RequiredDot", [_vm._v("Email")])]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.accountInfo.email,
                  callback: function($$v) {
                    _vm.$set(_vm.accountInfo, "email", $$v)
                  },
                  expression: "accountInfo.email"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Alternative Email",
                  rules: [_vm.rules.email],
                  clearable: ""
                },
                model: {
                  value: _vm.accountInfo.alternate_email,
                  callback: function($$v) {
                    _vm.$set(_vm.accountInfo, "alternate_email", $$v)
                  },
                  expression: "accountInfo.alternate_email"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { rules: [_vm.rules.required], clearable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [_c("RequiredDot", [_vm._v("First Name")])]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.accountInfo.first_name,
                  callback: function($$v) {
                    _vm.$set(_vm.accountInfo, "first_name", $$v)
                  },
                  expression: "accountInfo.first_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { rules: [_vm.rules.required], clearable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [_c("RequiredDot", [_vm._v("Last Name")])]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.accountInfo.last_name,
                  callback: function($$v) {
                    _vm.$set(_vm.accountInfo, "last_name", $$v)
                  },
                  expression: "accountInfo.last_name"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }