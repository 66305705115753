<template>
    <v-form v-model="valid" ref="form" lazy-validation>
        <v-card  tile flat>
<!--            <v-alert-->
<!--                    v-if="!checkInfoCompleted()"-->
<!--                    :value="true"-->
<!--                    color="primary"-->
<!--                    icon="info"-->
<!--                   style="margin-bottom: 16px;"-->
<!--                    outline>-->
<!--                Please complete billing information to activate your paid account. We will typically verify your information within 2 hours.-->
<!--            </v-alert>-->
            <Account ref="account" :accountInfo="clientInfo"></Account>
            <Contact ref="contact" :contactInfo="clientInfo" style="margin: 20px auto;"></Contact>
            <Social ref="social" :socialInfo="clientInfo"></Social>
            <v-card-actions>
                <v-layout class="mt-2" style="height: 60px;" align-content-end justify-center align-center>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" :loading="loading" color="primary" @click="updateUserInfo">Update</v-btn>
                </v-layout>
            </v-card-actions>
        </v-card>
        <v-dialog style="border-radius: 6px;" width="500" v-model="dialog" >
            <v-card>
                <v-card-text>
                    <v-layot column>
                        <v-flex  pt-4>
                            <img style="margin: 0 auto;width: 200px;display: block;height: 137px;"  :src="isGood?goodImg:uncertainImg" >
                        </v-flex>
                        <v-flex pb-4 style="text-align: center;" pt-4>
                            <span class="fs-4" :style="{color:isGood?'#18b23d':'#ffa700'}">{{isGood?'Your billing account is active now. Thank you for your business.':'Your billing information is under verified, it may take up to 2 hours. Thanks for your patience.'}}</span>
                        </v-flex>
                    </v-layot>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
    //The following information is required for a free trial.
    import Account from './_source/Account.vue'
    import Contact from './_source/Contact.vue'
    import Social from './_source/Social.vue'
    import goodImg from '@/assets/images/client_status/good.png'
    import uncertainImg from '@/assets/images/client_status/uncertain.png'
    import {mapState ,mapGetters,mapActions,mapMutations} from 'vuex'

    export default {
        name: "Profile",
        data(){
            return {
                dialog: false,
                loading : false,
                valid : false,
                url:'',
                isGood:false,
                goodImg,
                uncertainImg
            }
        },
        components: {Account, Contact, Social},
        computed: {
            ...mapState('client',{
                clientInfo: state => state.clientInfo
            }),
            ...mapGetters('client',['checkInfoCompleted']),
        },
        methods : {
            updateUserInfo() {
                if (!this.$refs.form.validate()){
                    return
                }
                this.loading = true
                const data = this.createClientData()
                this.$http({
                    method: 'put',
                    url: `/clients/${this.clientInfo.id}/`,
                    data: data
                }).then((res) => {
                    this.getClientInfo(true)
                    this.loading = false
                    this.autoVerifyResult(res)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    this.loading = false
                });
            },
            autoVerifyResult(res) {
                if (this.url) {
                    this.$router.push(this.url)
                } else {
                    this.isGood = res.status==='Good'
                    if (res.status === 'Uncertain'||(res.status === 'Good' && this.clientInfo.status!=='Good')) {
                        this.dialog = true
                    } else {
                        this.$message.success('Your profile was updated successfully!')
                    }
                }
                this.setClientInfo(res)
            },
            createClientData() {
                const ref = this.$refs
                return {
                    email: ref.account.accountInfo.email,
                    first_name: ref.account.accountInfo.first_name,
                    last_name: ref.account.accountInfo.last_name,
                    alternate_email: ref.account.accountInfo.alternate_email,
                    address1: ref.contact.contactInfo.address1,
                    address2: ref.contact.contactInfo.address2,
                    country: ref.contact.contactInfo.country,
                    state: ref.contact.contactInfo.state,
                    city: ref.contact.contactInfo.city,
                    company: ref.contact.contactInfo.company,
                    postcode: ref.contact.contactInfo.postcode,
                    phone_number: ref.contact.contactInfo.phone_number,
                    web_site: ref.social.socialInfo.web_site,
                    google_plus: ref.social.socialInfo.google_plus,
                    twitter: ref.social.socialInfo.twitter,
                    facebook: ref.social.socialInfo.facebook,
                    linkedin: ref.social.socialInfo.linkedin,
                    job_description: ref.social.socialInfo.job_description,
                }
            },
            ...mapActions('client',['getClientInfo']),
            ...mapMutations('client',['setClientInfo'])
        },
        created() {
            this.$SDK.track({
                pageName : 'Profile',
            })
        },
        mounted() {
            this.url = this.$route.query.return_url
        },
    }
</script>

