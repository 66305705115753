var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c("Account", {
            ref: "account",
            attrs: { accountInfo: _vm.clientInfo }
          }),
          _c("Contact", {
            ref: "contact",
            staticStyle: { margin: "20px auto" },
            attrs: { contactInfo: _vm.clientInfo }
          }),
          _c("Social", {
            ref: "social",
            attrs: { socialInfo: _vm.clientInfo }
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                {
                  staticClass: "mt-2",
                  staticStyle: { height: "60px" },
                  attrs: {
                    "align-content-end": "",
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { loading: _vm.loading, color: "primary" },
                      on: { click: _vm.updateUserInfo }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "border-radius": "6px" },
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layot",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", { attrs: { "pt-4": "" } }, [
                        _c("img", {
                          staticStyle: {
                            margin: "0 auto",
                            width: "200px",
                            display: "block",
                            height: "137px"
                          },
                          attrs: {
                            src: _vm.isGood ? _vm.goodImg : _vm.uncertainImg
                          }
                        })
                      ]),
                      _c(
                        "v-flex",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { "pb-4": "", "pt-4": "" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fs-4",
                              style: {
                                color: _vm.isGood ? "#18b23d" : "#ffa700"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isGood
                                    ? "Your billing account is active now. Thank you for your business."
                                    : "Your billing information is under verified, it may take up to 2 hours. Thanks for your patience."
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }