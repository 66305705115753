var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "", hover: "" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { grow: "" } },
            [_c("v-subheader", [_vm._v("Additional Information")])],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c("v-switch", {
                staticClass: "mt-2 pr-2",
                attrs: { color: "primary", dense: "", "hide-details": "" },
                model: {
                  value: _vm.show,
                  callback: function($$v) {
                    _vm.show = $$v
                  },
                  expression: "show"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          attrs: { row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Web Site", clearable: "" },
                model: {
                  value: _vm.socialInfo.web_site,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "web_site", $$v)
                  },
                  expression: "socialInfo.web_site"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Google+", clearable: "" },
                model: {
                  value: _vm.socialInfo.google_plus,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "google_plus", $$v)
                  },
                  expression: "socialInfo.google_plus"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Twitter", clearable: "" },
                model: {
                  value: _vm.socialInfo.twitter,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "twitter", $$v)
                  },
                  expression: "socialInfo.twitter"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Facebook", clearable: "" },
                model: {
                  value: _vm.socialInfo.facebook,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "facebook", $$v)
                  },
                  expression: "socialInfo.facebook"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pa-3": "" } },
            [
              _c("v-text-field", {
                attrs: { label: "Linkedin", clearable: "" },
                model: {
                  value: _vm.socialInfo.linkedin,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "linkedin", $$v)
                  },
                  expression: "socialInfo.linkedin"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md12: "", "pa-3": "" } },
            [
              _c("v-textarea", {
                attrs: { "auto-grow": "", solo: "", label: "Job Description" },
                model: {
                  value: _vm.socialInfo.job_description,
                  callback: function($$v) {
                    _vm.$set(_vm.socialInfo, "job_description", $$v)
                  },
                  expression: "socialInfo.job_description"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }