<template>
    <v-card tile hover>
        <v-layout>
            <v-flex grow>
                <v-subheader>Additional Information</v-subheader>
            </v-flex>
            <v-flex shrink>
                <v-switch
                        class="mt-2 pr-2"
                        v-model="show"
                        color="primary"
                        dense
                        hide-details
                ></v-switch>
            </v-flex>
        </v-layout>
        <v-divider></v-divider>
            <v-layout row wrap v-show="show">
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="socialInfo.web_site"
                            label="Web Site"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="socialInfo.google_plus"
                            label="Google+"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="socialInfo.twitter"
                            label="Twitter"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="socialInfo.facebook"
                            label="Facebook"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="socialInfo.linkedin"
                            label="Linkedin"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12  pa-3>
                    <v-textarea
                            auto-grow
                            solo
                            v-model="socialInfo.job_description"
                            label="Job Description"
                    ></v-textarea>
                </v-flex>
            </v-layout>
    </v-card>
</template>

<script>
    import rules from '@/module/rules/index.js'
    export default {
        name: "Social",
        props:{
            socialInfo: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                rules: {...rules}
            }
        }
    }
</script>

