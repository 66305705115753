<template>
    <v-card tile hover>
        <v-subheader>Edit Billing Information</v-subheader>
        <v-divider></v-divider>
            <v-layout row wrap>
                <v-flex xs12 sm6 pa-3>
                    <v-autocomplete
                            v-model="contactInfo.country"
                            :items="countryList"
                            @change="getStates"
                            item-text="name"
                            :rules="[rules.required]"
                            hide-details
                            hide-no-data>
                        <template v-slot:label>
                            <RequiredDot>Country / Region</RequiredDot>
                        </template>
                    </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-autocomplete
                            :disabled="disabledState || this.stateList.length===0"
                            v-model="contactInfo.state"
                            :items="stateList"
                            item-text="name"
                            :rules="[rules.required]"
                            hide-details
                            hide-no-data>
                        <template v-slot:label>
                            <RequiredDot>State</RequiredDot>
                        </template>
                    </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.city"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>City</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.address1"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>Address 1</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.address2"
                            label="Address 2"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.postcode"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>Postcode</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.company"
                            label="Company"
                            clearable
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-3>
                    <v-text-field
                            v-model="contactInfo.phone_number"
                            :rules="[rules.required]"
                            clearable>
                        <template v-slot:label>
                            <RequiredDot>Phone Number</RequiredDot>
                        </template>
                    </v-text-field>
                </v-flex>
            </v-layout>
    </v-card>
</template>

<script>
    import rules from '@/module/rules/index.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import {mapActions} from 'vuex'
    export default {
        name: "Contact",
        components: {RequiredDot},
        data() {
            return {
                disabledState: false,
                countryList : [],
                stateList : [],
                rules: {...rules}
            }
        },
        props: {
            contactInfo: {
                type: Object,
                required: true
            }
        },
        methods: {
            getStates(countryName){
                let country = this.countryList.find( o => o.name === countryName)
                if(!country){
                    this.stateList = []
                }else{
                    this.disabledState = true
                    this.$http.get('/states/' ,{
                        params : {
                            country:country.id,
                            page_size:300
                        }
                    }).then(res => {
                        this.disabledState = false
                        this.stateList = res.results
                    }).catch(() => {
                        this.disabledState = false
                    })
                }
            },
            ...mapActions('client', ['getCountries'])
        },
        created(){
            this.getCountries().then(res => {
                this.countryList = res
                if(this.contactInfo.country){
                    this.getStates(this.contactInfo.country)
                }
            })
        }
    }
</script>

